import React from "react";
import LivePreview from "../../../../src/components/live-preview";

export function FormWithStyles() {
  return (
    <LivePreview>
      <p css={{ marginBlockEnd: "1rem" }}>All fields are required.</p>
      <form
        css={{
          display: "flex",
          flexWrap: "wrap",
          gap: "12px",
          marginBlockEnd: "1rem",
          "input:focus:user-valid": {
            outlineColor: "green",
          },
          "input:focus:user-invalid": {
            outlineColor: "tomato",
          },
          '& div[role="alert"]': {
            color: "tomato",
            display: "none",
          },
          '&:has(:user-invalid) div[role="alert"]': {
            display: "block",
          },
        }}
      >
        <label>
          Email:{" "}
          <input type="email" required pattern=".+@example.com" name="email" />
        </label>
        <label>
          Password: <input type="password" required name="password" />
        </label>
        <button type="submit">Log in</button>
        <div role="alert">Form is invalid.</div>
      </form>
    </LivePreview>
  );
}

export function FormSubmission() {
  const handleSubmit = (event) => {
    // Prevent default form submission
    event.preventDefault();

    // 1. Grab individual form element value
    const email = event.target.email.value;
    const password = event.target.password.value;

    // 2. Use FormData API to capture individual form element value
    const formData = new FormData(event.target);
    const emailValue = formData.get("email");
    const passwordValue = formData.get("password");

    // 3. Use FormData API to capture all form element values
    const data = Object.fromEntries(formData);

    console.log({ data, email, emailValue, password, passwordValue }); // Will print the form data
    // You can send a POST request to your backend here
  };
  return (
    <LivePreview>
      <p css={{ marginBlockEnd: "1rem" }}>All fields are required.</p>
      <form
        onSubmit={handleSubmit}
        css={{
          display: "flex",
          flexWrap: "wrap",
          gap: "12px",
          marginBlockEnd: "1rem",
          "input:focus:user-valid": {
            outlineColor: "green",
          },
          "input:focus:user-invalid": {
            outlineColor: "tomato",
          },
          '& div[role="alert"]': {
            color: "tomato",
            display: "none",
          },
          '&:has(:user-invalid) div[role="alert"]': {
            display: "block",
          },
        }}
      >
        <label>
          Email:{" "}
          <input type="email" required pattern=".+@example.com" name="email" />
        </label>
        <label>
          Password: <input type="password" required name="password" />
        </label>
        <button type="submit">Log in</button>
        <div role="alert">Form is invalid.</div>
      </form>
    </LivePreview>
  );
}
