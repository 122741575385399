/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import React from 'react';
import LivePreview from '../../../../src/components/live-preview';

export function RequiredFieldForm() {
  return (
    <LivePreview>
      <p css={{ marginBlockEnd: '1rem' }}>All fields are required.</p>
      <form
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '12px',
          marginBlockEnd: '1rem',
        }}
      >
        <label>
          Email: <input type="email" required />
        </label>
        <label>
          Password: <input type="password" required />
        </label>
        <button type="submit">Log in</button>
      </form>
    </LivePreview>
  );
}

export function EmailValidation() {
  return (
    <LivePreview>
      <p css={{ marginBlockEnd: '1rem' }}>All fields are required.</p>
      <form
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '12px',
          marginBlockEnd: '1rem',
        }}
      >
        <label>
          Email: <input type="email" required pattern=".+@example.com" />
        </label>
        <label>
          Password: <input type="password" required />
        </label>
        <button type="submit">Log in</button>
      </form>
    </LivePreview>
  );
}

export function FormWithStyles() {
  return (
    <LivePreview>
      <p css={{ marginBlockEnd: '1rem' }}>All fields are required.</p>
      <form
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '12px',
          marginBlockEnd: '1rem',
          'input:focus:valid': {
            outlineColor: 'green',
          },
          'input:focus:invalid': {
            outlineColor: 'tomato',
          },
        }}
      >
        <label>
          Email: <input type="email" required pattern=".+@example.com" />
        </label>
        <label>
          Password: <input type="password" required />
        </label>
        <button type="submit">Log in</button>
      </form>
    </LivePreview>
  );
}

export function BrokenFormValidation() {
  return (
    <LivePreview>
      <p css={{ marginBlockEnd: '1rem' }}>All fields are required.</p>
      <form
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '12px',
          marginBlockEnd: '1rem',
        }}
      >
        <label>
          Email: <input type="email" required pattern=".+@example.com" />
        </label>
        <label>
          Password: <input type="password" required />
        </label>
        <button
          onClick={(e) => {
            e.preventDefault();
            console.log('No more form inbuilt validation');
          }}
        >
          Log in
        </button>
      </form>
      <p>
        <em>
          <small>
            *As we have used <code>event.preventDefault</code> inside the click
            handler, we have broken down a nice auto validation feature.
          </small>
        </em>
      </p>
    </LivePreview>
  );
}

export function FormProcessing() {
  return (
    <LivePreview>
      <p css={{ marginBlockEnd: '1rem' }}>All fields are required.</p>
      <form
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '12px',
          marginBlockEnd: '1rem',
        }}
        action="/login"
        method="POST"
      >
        <label>
          Email:{' '}
          <input type="email" name="email" required pattern=".+@example\.com" />
        </label>
        <label>
          Password: <input type="password" name="password" required />
        </label>
        <button type="submit">Log in</button>
      </form>
    </LivePreview>
  );
}
