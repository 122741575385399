/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable implicit-arrow-linebreak */
// Modal with initial focus placement
import React, { useRef, useEffect } from 'react';
import FocusLock from '../../../../src/components/focus-lock';
import '../modal.css';

const Modal = ({
  isOpen,
  onDismiss,
  children,
  initialFocusRef,
  focusOnCloseRef,
  ...props
}) => {
  // To capture a reference to our modal DOM instance
  const dialogRef = useRef();

  // The following ref is required to extract
  // the focusable child element.
  const firstFocusable = useRef();

  // Require to store previous props value for comparison.
  // Can be converted into a hook too
  const prevRef = useRef();

  // NOTE: Order of these hooks is important
  useEffect(() => {
    if (prevRef.current === true && isOpen === false) {
      // invoking the focus method when modal state
      // changes from `open -> close`
      focusOnCloseRef?.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    prevRef.current = isOpen;
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      // Check if there's any element ref being passed to
      // set initial focus to.
      if (initialFocusRef?.current) {
        initialFocusRef.current.focus();
        return;
      }

      firstFocusable.current = dialogRef.current?.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), video',
      )[0];

      // Otherwise, we will focus the first focusable element.
      firstFocusable.current?.focus({ focusVisible: true });
    }
  }, [isOpen]);

  const handleBackdropClick = (event) => {
    // return early if click region inside the dialog
    if (dialogRef.current?.contains(event.target)) {
      return;
    }
    onDismiss();
  };

  return isOpen ? (
    <div className="overlay" onClick={handleBackdropClick}>
      <div
        ref={dialogRef}
        className="content-wrapper"
        role="dialog"
        aria-modal="true"
        {...props}
      >
        <FocusLock>{children}</FocusLock>
      </div>
    </div>
  ) : null;
};

export default Modal;
