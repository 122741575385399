/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable no-plusplus */
import React, { useEffect, useId } from 'react';
import styled from 'styled-components';

const TableContext = React.createContext();

const TableProvider = ({ value, children }) => (
  <TableContext.Provider value={value}>{children}</TableContext.Provider>
);

const useTableContext = () => React.useContext(TableContext);

export const TableContainer = (props) => {
  const captionId = useId();
  useEffect(() => {
    try {
      const allTables = document.querySelectorAll('table');
      for (let i = 0; i < allTables.length; i++) {
        allTables[i].setAttribute('role', 'table');
      }
      const allRowGroups = document.querySelectorAll('thead, tbody, tfoot');
      for (let i = 0; i < allRowGroups.length; i++) {
        allRowGroups[i].setAttribute('role', 'rowgroup');
      }
      const allRows = document.querySelectorAll('tr');
      for (let i = 0; i < allRows.length; i++) {
        allRows[i].setAttribute('role', 'row');
      }
      const allCells = document.querySelectorAll('td');
      for (let i = 0; i < allCells.length; i++) {
        allCells[i].setAttribute('role', 'cell');
      }
      const allHeaders = document.querySelectorAll('th');
      for (let i = 0; i < allHeaders.length; i++) {
        allHeaders[i].setAttribute('role', 'columnheader');
      }
      // this accounts for scoped row headers
      const allRowHeaders = document.querySelectorAll('th[scope=row]');
      for (let i = 0; i < allRowHeaders.length; i++) {
        allRowHeaders[i].setAttribute('role', 'rowheader');
      }
      // caption role not needed as it is not a real role and
      // browsers do not dump their own role with display block
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`AddTableARIA(): ${e}`);
    }
  }, []);
  return (
    <TableProvider value={captionId}>
      <div
        role="region"
        css={{
          overflow: 'auto',
        }}
        {...props}
        tabIndex="0"
        aria-labelledby={captionId}
      />
    </TableProvider>
  );
};

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 16px;
`;

export const TableCaption = (props) => {
  const captionId = useTableContext();
  return (
    <caption
      id={captionId}
      {...props}
      css={{
        height: 0,
        width: 0,
        overflow: 'hidden',
      }}
    />
  );
};

export const Thead = styled.thead``;
export const Tbody = styled.tbody``;

export const Th = styled.th`
  vertical-align: bottom;
  background: var(--primary-color);
  font-weight: bold;
  padding-block: 0.5em;
  padding-inline-end: 0.25em;
  text-align: left;
  white-space: nowrap;
  text-indent: -0.5em;
  /* This is to override the default table styles */
  && {
    padding-inline-start: 1em;
    padding-inline-end: 0.25em;
  }

  @media (max-width: 37rem) {
    display: none;
  }
`;

export const Td = styled.td`
  padding: 0.5em 0.5em 0.5em 1em;
  vertical-align: text-top;
  text-align: left;
  text-indent: -0.5em;
  white-space: nowrap;
  /* This is to override the default table styles */
  && {
    padding-inline-start: 1em;
    padding-inline-end: 0.25em;
  }

  @media (max-width: 37rem) {
    display: grid;
    grid-template-columns: 15ch auto;
    grid-gap: 1rem 0.5rem;
    white-space: normal;
    word-break: break-word;
    hyphens: auto;

    &:first-child {
      padding-block-start: 1rem;
    }
    &:last-child {
      padding-block-end: 1rem;
    }
    &::before {
      content: attr(data-cell) ': ';
      font-weight: 700;
      text-transform: capitalize;
    }
  }
`;

export const Tr = styled.tr`
  :nth-child(even) {
    background-color: hsla(var(--pallete-pink-50), 10%);
  }
`;
