import { TableContainer, Table, TableCaption, Thead, Tbody, Tr, Th, Td } from "../../../../src/components/Table";
import * as React from 'react';
export default {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  React
};