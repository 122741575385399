import React, { useState, useRef } from 'react';
import Button from '../../../../src/components/button';

import Modal from './modal-4';

const Example4 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  // Required to store reference to button 2 element
  const button2Ref = useRef();

  return (
    <div>
      <Button onClick={open}>Open modal</Button>
      <Modal isOpen={isOpen} onDismiss={close} initialFocusRef={button2Ref}>
        <p>
          As we have passed a reference to `Button 2` element, you can observe
          how the initial focus is set on the
          {' '}
          <b>Button 2</b>
          .
        </p>
        <div css={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
          <Button onClick={close}>Close</Button>
          <Button>Button 1</Button>
          <Button ref={button2Ref}>Button 2</Button>
          <Button>Button 3</Button>
        </div>
      </Modal>
    </div>
  );
};

export default Example4;
