/* eslint-disable no-use-before-define */
/* eslint-disable react/button-has-type */
import React, { useState, lazy, useRef } from 'react';
import Button from '../../../../src/components/button';

const Modal = lazy(() => import('./modal-4'));

const Example5 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => {
    setIsOpen(false);
    focusOnCloseRef.current.focus();
  };

  // To store ref to an element that invokes the modal
  const focusOnCloseRef = useRef();

  return (
    <div>
      <Button onClick={open} ref={focusOnCloseRef}>
        Open modal
      </Button>
      {isOpen && (
        <React.Suspense fallback={<p>loading...</p>}>
          <Modal isOpen={isOpen} onDismiss={close}>
            <p>Once modal closes, focus will land on triggering element.</p>
            <Button onClick={close}>Close</Button>
          </Modal>
        </React.Suspense>
      )}
    </div>
  );
};

export default Example5;
