import React from 'react';
import styled from 'styled-components';

export default function LivePreview({ children }) {
  return (
    <div
      css={{
        display: 'flex',
        gap: '12px',
        flexDirection: 'column',
        paddingInline: '12px',
        paddingBlock: '16px',
        borderRadius: 'var(--border-radius-lg)',
        border: '2px solid var(--primary-color)',
        boxShadow: 'var(--card-box-shadow-md)',
      }}
    >
      <Live>Live Preview</Live>
      {children}
    </div>
  );
}

const Live = styled.span`
  margin-inline-start: auto;
  background-color: var(--syntax-lang-bg);
  margin-block-start: -1rem;
  padding-inline-start: 0.5rem;
  padding-inline-end: 0.5rem;
  text-transform: uppercase;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  font-family: Montserrat;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  border: 2px solid var(--primary-color);
  border-top-width: 0;
  font-size: 0.875rem;
`;
