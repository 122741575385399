import React, { useState, lazy } from 'react';

import Button from '../../../../src/components/button';

const Modal = lazy(() => import('./modal-2'));

const Example2 = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div css={{ marginBottom: '2rem' }}>
      <Button onClick={() => setIsOpen(true)}>Open modal</Button>
      {isOpen && (
        <React.Suspense fallback={<p>loading...</p>}>
          <Modal isOpen={isOpen} onDismiss={() => setIsOpen(false)}>
            <p>Hi, I am the modal content 👋. Try clicking outside of me!</p>
            <Button onClick={() => setIsOpen(false)}>Close</Button>
          </Modal>
        </React.Suspense>
      )}
    </div>
  );
};

export default Example2;
