import React, { useState, lazy } from 'react';

import Button from '../../../../src/components/button';

const Modal = lazy(() => import('./modal-3'));

const Example3 = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div css={{ marginBottom: '2rem' }}>
      <Button onClick={() => setIsOpen(true)}>Open modal</Button>
      {isOpen && (
        <React.Suspense fallback={<p>loading...</p>}>
          <Modal
            isOpen={isOpen}
            onDismiss={() => setIsOpen(false)}
            aria-labelledby="modal-title"
          >
            <h3 id="modal-title">Modal title</h3>
            <p>Hi, I am the modal content👋, and I am more Accessible now.</p>
            <Button onClick={() => setIsOpen(false)}>Close</Button>
          </Modal>
        </React.Suspense>
      )}
    </div>
  );
};

export default Example3;
