/* eslint-disable no-use-before-define */
/* eslint-disable react/button-has-type */
import React, { useState, useRef } from 'react';
import Button from '../../../../src/components/button';

import Modal from './modal-6';

const Example8 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  // To store ref to an element that invokes the modal
  const focusOnCloseRef = useRef();

  return (
    <div>
      <Button onClick={open} ref={focusOnCloseRef}>
        Open modal
      </Button>
      <Modal
        isOpen={isOpen}
        onDismiss={close}
        focusOnCloseRef={focusOnCloseRef}
      >
        <p>
          Try
          {' '}
          <code>Tabbing</code>
          {' '}
          through these elements. Focus is trapped now
          🔒!
        </p>
        <div css={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
          <Button>one</Button>
          <Button>two</Button>
          <Button>three</Button>
          <Button onClick={close}>Close</Button>
        </div>
      </Modal>
    </div>
  );
};

export default Example8;
