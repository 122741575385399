/* eslint-disable react/button-has-type */
import React, { useState, lazy } from 'react';
import Button from '../../../../src/components/button';

const Modal = lazy(() => import('./modal-4'));

const Example4 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return (
    <div>
      <Button onClick={open}>Open modal</Button>
      {isOpen && (
        <React.Suspense fallback={<p>loading...</p>}>
          <Modal isOpen={isOpen} onDismiss={close}>
            <p>
              Hi, I am the modal content 👋. You can observe how the initial
              focus is set on the
              {' '}
              <b>close</b>
              {' '}
              button by default.
            </p>
            <Button onClick={close}>Close</Button>
          </Modal>
        </React.Suspense>
      )}
    </div>
  );
};

export default Example4;
