/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import rangeParser from 'parse-numeric-range';
// Code block theming
import theme from 'prism-react-renderer/themes/oceanicNext';
import useClipboard from './hooks/use-copy-clipboard';

const calculateLinesToHighlight = (raw) => {
  const lineNumbers = rangeParser(raw);
  if (lineNumbers) {
    return (index) => lineNumbers.includes(index + 1);
  }
  return () => false;
};

// Styled components ------------------
const Language = (props) => (
  <div
    css={{
      backgroundColor: 'var(--syntax-lang-bg)',
      marginRight: '1rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      textTransform: 'uppercase',
      borderBottomLeftRadius: '0.5rem',
      borderBottomRightRadius: '0.5rem',
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'var(--text-color)',
    }}
    {...props}
  />
);

const File = (props) => (
  <div
    css={{
      color: 'var(--neutral-light)',
      fontFamily: 'Montserrat',
      fontStyle: 'italic',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    {...props}
  />
);

const CopyButton = (props) => (
  <button
    type="button"
    css={{
      marginRight: '1.5rem',
      marginTop: '0.5rem',
      padding: '0.5rem 0.75rem',
      backgroundColor: 'var(--highlight-color)',
      border: 'none',
      borderRadius: 'var(--border-radius-lg)',
      cursor: 'pointer',
      color: 'var(--neutral-light)',
      fontSize: '0.875rem',
      fontFamily: 'sans-serif',
      lineHeight: '1',
      marginLeft: 'auto',
    }}
    {...props}
  />
);

const Code = (props) => {
  const className = props.children.props.className || '';
  const code = props.children.props.children.trim();
  const language = className.replace(/language-/, '');
  const { file } = props.children.props;
  const highlights = calculateLinesToHighlight(props.children.props.highlights || '');

  const { hasCopied, onCopy } = useClipboard(code);

  return (
    <div
      css={{
        background: 'var(--syntax-block-bg)',
        borderRadius: 'var(--border-radius-lg)',
        marginTop: '2rem',
        marginBottom: '2rem',
        paddingLeft: '1.5rem',
      }}
    >
      <div css={{ display: 'flex', position: 'relative' }}>
        {language && <Language>{language}</Language>}
        {file && <File>{file}</File>}
        <CopyButton onClick={onCopy}>{hasCopied ? 'Copied' : 'Copy'}</CopyButton>
      </div>
      <div
        css={{
          overflow: 'auto',
          background: 'var(--code-block-bg)',
          borderRadius: 'var(--border-radius-lg)',
        }}
      >
        <Highlight {...defaultProps} code={code} language={language} theme={theme}>
          {({
            className: css, style, tokens, getLineProps, getTokenProps,
          }) => (
            <pre
              className={css}
              css={{
                ...style,
                backgroundColor: 'transparent',
                float: 'left',
                minWidth: '100%',
              }}
            >
              {tokens.map((line, i) => (
                <div
                  {...getLineProps({ line, key: i })}
                  css={{
                    background: highlights(i) ? 'var(--highlight-color)' : 'transparent',
                    display: 'block',
                  }}
                >
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight>
      </div>
    </div>
  );
};

export default Code;
