import React, { useState, lazy } from 'react';
import Button from '../../../../src/components/button';

const Modal = lazy(() => import('./modal-1'));

const Example1 = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Button onClick={() => setIsOpen(true)}>Open modal</Button>
      {isOpen && (
        <React.Suspense fallback={<p>loading...</p>}>
          <Modal isOpen={isOpen}>
            <p>Hi, I am the modal content 👋</p>
            <Button onClick={() => setIsOpen(false)}>Close</Button>
          </Modal>
        </React.Suspense>
      )}
    </div>
  );
};

export default Example1;
