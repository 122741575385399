/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import Button from '../../../src/components/button';
import LivePreview from '../../../src/components/live-preview';

export default function LoginForm() {
  const [error, setError] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    const {
      password: { value },
    } = e.target.elements;
    // Dummy logic of returning error when password mismatches
    if (value !== 'test') {
      setError(true);
    } else {
      setError(false);
    }
  }
  return (
    <LivePreview>
      <form onSubmit={handleSubmit}>
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '12px',
            marginBlockEnd: '1rem',
          }}
        >
          <label>
            Username:
            {' '}
            <input type="text" name="username" required value="screen reader" />
          </label>
          <label>
            Password:
            {' '}
            <input type="password" name="password" required />
          </label>
        </div>
        <Button type="submit">Log in</Button>
      </form>

      <div
        aria-live="polite"
        aria-atomic="true"
        role="alert"
        id="errorContainer"
      >
        {error && (
          <span style={{ color: 'red' }}>
            Please check your credentials and try again.
          </span>
        )}
      </div>
      <p css={{ marginBlock: '0.5rem' }}>
        <em>
          <small>*Screen reader users will get an error feedback.</small>
        </em>
      </p>
    </LivePreview>
  );
}
