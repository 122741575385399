import { useCallback, useState, useEffect } from 'react';
import copyToClipboard from '../../utils/copy-to-clipboard';

/**
 * useClipboard - React hook to copy contents to clipboard
 *
 * @param {string} textToCopy text or value to copy
 * @param {number} [timeout=1500] delay (in ms) to switch back to initial state once copied
 */
const useClipboard = (textToCopy, timeout = 1500) => {
  const [hasCopied, setHasCopied] = useState(false);

  const onCopy = useCallback(async () => {
    try {
      await copyToClipboard(textToCopy);
      setHasCopied(true);
    } catch {
      setHasCopied(false);
    }
  }, [textToCopy]);

  useEffect(() => {
    let timeoutId = null;

    if (hasCopied) {
      timeoutId = setTimeout(() => setHasCopied(false), timeout);
    }

    return () => timeoutId && clearTimeout(timeoutId);
  }, [timeout, hasCopied]);

  return { value: textToCopy, onCopy, hasCopied };
};

export default useClipboard;
