/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable implicit-arrow-linebreak */
// Modal with initial focus placement
import React, { useRef, useEffect } from 'react';

import '../modal.css';

const Modal = ({ isOpen, onDismiss, children, initialFocusRef, ...props }) => {
  // To capture a reference to our modal DOM instance
  const dialogRef = useRef();

  // The following ref is required to extract
  // the focusable child element.
  const firstFocusable = useRef();

  useEffect(() => {
    if (isOpen) {
      // Check if there's any element ref being passed to
      // set initial focus to.
      if (initialFocusRef?.current) {
        initialFocusRef.current.focus();
        return;
      }

      firstFocusable.current = dialogRef.current?.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), video',
      )[0];

      // Otherwise, we will focus the first focusable element.
      firstFocusable.current?.focus({ focusVisible: true });
    }
  }, [isOpen]);

  const handleBackdropClick = (event) => {
    // return early if click region inside the dialog
    if (dialogRef.current?.contains(event.target)) {
      return;
    }
    onDismiss();
  };

  return isOpen ? (
    <div className="overlay" onClick={handleBackdropClick}>
      <div
        ref={dialogRef}
        className="content-wrapper"
        role="dialog"
        aria-modal="true"
        {...props}
      >
        {children}
      </div>
    </div>
  ) : null;
};

export default Modal;
