import React, { useEffect, useRef } from 'react';

const TAB_KEY = 9;

const FocusLock = (props) => {
  const rootNode = useRef(null);
  const focusableItems = useRef([]);
  useEffect(() => {
    focusableItems.current = rootNode.current.querySelectorAll(
      `button, [href], input, select, textarea, 
  [tabindex]:not([tabindex="-1"]), video`,
    );
  }, []);

  useEffect(() => {
    const handleKeydown = (event) => {
      if (!focusableItems.current) {
        return;
      }

      const { keyCode, shiftKey } = event;
      const {
        length,
        0: firstItem,
        [length - 1]: lastItem,
      } = focusableItems.current;

      if (keyCode === TAB_KEY) {
        // If only one item then prevent tabbing
        if (length === 1) {
          event.preventDefault();
          return;
        }

        // If focused on last item
        // then focus on first item when tab is pressed
        if (!shiftKey && document.activeElement === lastItem) {
          event.preventDefault();
          firstItem.focus();
          return;
        }

        // If focused on first item
        // then focus on last item when shift + tab is pressed
        if (shiftKey && document.activeElement === firstItem) {
          event.preventDefault();
          lastItem.focus();
        }
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, []);
  return <div ref={rootNode} {...props} />;
};

export default FocusLock;
