import React, { forwardRef } from 'react';

const Button = forwardRef((props, ref) => (
  <button
    ref={ref}
    type="button"
    css={{
      backgroundColor: 'var(--primary-color)',
      color: '#fff',
      border: 'none',
      minInlineSize: '10ch',
      padding: '0.3em 1em',
      borderRadius: '4px',
      ':focus': {
        outline: 'max(2px, 0.1em) solid hsla(var(--pallete-pink-70))',
      },
    }}
    {...props}
  />
));

export default Button;
