/* eslint-disable no-use-before-define */
/* eslint-disable react/button-has-type */
import React, { useState, useRef } from 'react';
import Button from '../../../../src/components/button';

import Modal from './modal-5';

const Example7 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  // To store ref to an element that invokes the modal
  const focusOnCloseRef = useRef();

  return (
    <div>
      <Button onClick={open} ref={focusOnCloseRef}>
        Open modal
      </Button>
      <Modal
        isOpen={isOpen}
        onDismiss={close}
        focusOnCloseRef={focusOnCloseRef}
      >
        <p>Once modal closes, focus will land on triggering element.</p>
        <Button onClick={close}>Close</Button>
      </Modal>
    </div>
  );
};

export default Example7;
