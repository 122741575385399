import Example4 from "../../../../content/blog/how-to-create-an-accessible-modal-component-in-react-part-2/preview/example-4";
import Example5 from "../../../../content/blog/how-to-create-an-accessible-modal-component-in-react-part-2/preview/example-5";
import Example6 from "../../../../content/blog/how-to-create-an-accessible-modal-component-in-react-part-2/preview/example-6";
import Example7 from "../../../../content/blog/how-to-create-an-accessible-modal-component-in-react-part-2/preview/example-7";
import Example8 from "../../../../content/blog/how-to-create-an-accessible-modal-component-in-react-part-2/preview/example-8";
import Example9 from "../../../../content/blog/how-to-create-an-accessible-modal-component-in-react-part-2/preview/example-9";
import Example10 from "../../../../content/blog/how-to-create-an-accessible-modal-component-in-react-part-2/preview/example-10";
import * as React from 'react';
export default {
  Example4,
  Example5,
  Example6,
  Example7,
  Example8,
  Example9,
  Example10,
  React
};